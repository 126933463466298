import { render, staticRenderFns } from "./ChairmanMessage.vue?vue&type=template&id=296f686c&scoped=true&"
import script from "./ChairmanMessage.vue?vue&type=script&lang=js&"
export * from "./ChairmanMessage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "296f686c",
  null
  
)

export default component.exports